a {
  text-decoration: none;
}

// Colors
.primary-color {
  color: var(--primary-color) !important;
}

.hove-primary:hover {
  color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--secondary-color) !important;
}

.dark-color {
  color: var(--dark-color) !important;
}

.success-color {
  color: var(--success-color) !important;
}

.white-color {
  color: var(--white-color) !important;
}

.gray-color {
  color: var(--gray-color) !important;
}

.grayXS-color {
  color: var(--grayXS-color);
}

.grayMD-color {
  color: var(--grayMD-color);
}

.error-color {
  color: var(--error-color) !important;
}

.warning-color {
  color: var(--warning-color);
}

.bs-primaryMD {
  border: 1px solid var(--primaryMD-color);
}

.info-color {
  color: var(--info-color);
}

// Background Colors
.bg-inherit {
  background-color: transparent !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-success {
  background-color: var(--success-color) !important;
}

.bg-successXS {
  background-color: var(--successXS-color) !important;
}

.bg-primaryXS {
  background-color: var(--primaryXS-color) !important;
}

.bg-primarySM {
  background-color: var(--primarySM-color) !important;
}

.bg-whiteLG {
  background-color: var(--whiteXL-color) !important;
}

.bg-whiteMD {
  background-color: var(--whiteMD-color) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-error {
  background-color: var(--error-color) !important;
}

.bg-errorSM {
  background-color: var(--errorT-color) !important;
}

.bg-gray {
  background-color: var(--gray-color);
}

.bg-grayXS {
  background-color: var(--grayXS-color);
}

.bg-graySM {
  background-color: var(--graySM-color) !important;
}

.bg-black {
  background-color: var(--black-color);
}

.bg-warning {
  background-color: var(--warning-color);
}

.bg-warningSM {
  background-color: var(--warningSM-color);
}

.bg-warningXS {
  background-color: var(--warningXS-color);
}

.bg-primaryT {
  background-color: var(--primarySM-color);
}

html {
  scroll-behavior: smooth;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  appearance: none;
  -moz-appearance: textfield;
}

.form-title {
  margin: 0px;
  letter-spacing: 0.5px;
  font-size: 22px;
  margin-right: 10px;

  @media (max-width: 650px) {
    font-size: 18px;
  }
}