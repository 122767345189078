@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  background-color: var(--aliceblue-color);
  -webkit-tap-highlight-color: transparent;
  font-family: var(--font-base);
}

:root {
  --font-base: "Roboto", sans-serif;
  --primary-color: #007bff;
  --primaryDark-color: #007bff;
  --primaryXS-color: rgba(35 116 225 / 5%);
  --primarySM-color: rgb(0 109 255 / 10%);
  --primaryMD-color: rgba(35 116 225 / 20%);
  --primaryLG-color: rgba(35 116 225 / 50%);
  --success-color: #00cf87;
  --successXS-color: rgba(0, 227, 150, 0.1);
  --warning-color: #f6d110;
  --warningSM-color: rgba(233 186 0 / 12%);
  --warningXS-color: rgba(233 186 0 / 10%);
  --info-color: #00e1ff;
  --error-color: #ff0000;
  --errorT-color: rgba(255 0 0 / 10%);
  --aliceblue-color: #f0f8ffdb;
  --white-color: #fff;
  --whiteMD-color: rgba(255 255 255 / 30%);
  --whiteLG-color: rgba(255, 255, 255, 0.502);
  --whiteXL-color: rgba(255 255 255 / 70%);
  --black-color: #000;
  --dark-color: #3d4e5f;
  --gray-color: #7a7a7a;
  --grayXS-color: #7a7a7a10;
  --graySM-color: #7a7a7a1f;
  --grayMD-color: #aaa6a686;

  --shadow-xs: rgb(0 123 255 / 5%) 0px 8px 8px;
  --shadow-sm: rgb(0 123 255 / 10%) 0px 8px 10px;
  --shadow-md: rgb(0 123 255 / 15%) 0px 8px 10px;
  --shadow-lg: rgb(0 123 255 / 20%) 0px 8px 24px;
  --shadow-xl: rgb(0 123 255 / 30%) 0px 8px 24px;
}
