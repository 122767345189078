.appbar {
  border: none !important;

  .appbar-container {
    padding: 0px;
    background: var(--white-color);
    border: 1px solid var(--primaryMD-color);
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin: 10px;
    min-width: 300px;

    .appbar-inner-div {
      padding: 0px;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.sidebar-card {
  background-color: var(--primaryXS-color);
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 15px 20px 12px 0px;
  border-radius: 0px 15px 15px 0px;
}